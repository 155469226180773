import React from 'react'
import { Icon } from '@aider/ui'

const FeatureListItem = ({ title, free = false }) => {
	return (
		<li className="flex flex-wrap py-4 border-t border-gray-300">
			<p className="w-full md:w-1/3 text-base text-black font-medium mb-2">
				{title}
			</p>
			<div className="w-1/2 md:w-1/3 flex md:justify-center">
				<div className="flex items-center space-x-2">
					{free ? (
						<Icon
							name="checkbox-circle"
							className="h-4 w-4 md:h-5 md:w-5 fill-current text-blue-500"
						/>
					) : (
						<Icon
							name="cross"
							className="h-3 w-3 md:h-3 md:w-3 fill-current text-gray-400"
						/>
					)}
					<p className="md:hidden text-sm font-medium">Grund</p>
				</div>
			</div>
			<div className="w-1/2 md:w-1/3 flex md:justify-center">
				<div className="flex items-center space-x-2">
					<Icon
						name="checkbox-circle"
						className="h-4 w-4 md:h-5 md:w-5 fill-current text-blue-500"
					/>
					<p className="md:hidden text-sm font-medium">Premium</p>
				</div>
			</div>
		</li>
	)
}

export default FeatureListItem
