import React from 'react'
import { Icon } from '@aider/ui'
import Button from './Button'
import styled from 'styled-components'
import tw from 'twin.macro'

const PricingCard = ({
	title,
	description,
	price,
	priceNote,
	buttonTitle,
	buttonTo,
	buttonHref,
	buttonVariant,
	features,
	featureNote,
	isPremium,
}) => {
	return (
		<Container
			isPremium={isPremium}
			className="relative z-10 flex flex-1 flex-col"
		>
			<div
				className={`overflow-hidden rounded-xl bg-white ${
					isPremium ? null : 'border border-gray-300'
				}`}
			>
				<div className="p-6">
					<h3 className="font-semibold text-lg mb-2">{title}</h3>
					<h2 className="text-gray-700 max-w-xs">{description}</h2>
					<div className="py-4">{price}</div>
					<Button
						variant={buttonVariant}
						title={buttonTitle}
						to={buttonTo}
						href={buttonHref}
						className="block text-center"
					/>
					{priceNote && (
						<small className="mt-2 block text-gray-600">
							{' '}
							{priceNote}{' '}
						</small>
					)}
				</div>
				<div className="bg-gray-200 p-6">
					<div className="mb-4 font-medium">
						<p className="font-medium">Vad ingår?</p>
						{featureNote && (
							<p className="mt-2 text-gray-500 ">{featureNote}</p>
						)}
					</div>
					<ul>
						{features.map((feature, index) => (
							<li
								key={index}
								className="flex space-x-2 items-center mb-2"
							>
								<Icon
									name="checkbox-circle"
									className="fill-current text-blue-500 w-5 h-5"
								/>
								<span className="font-medium">{feature}</span>
							</li>
						))}
					</ul>
				</div>
			</div>
		</Container>
	)
}

const Container = styled.div`
	&:after {
		content: '';
		border-radius: 16px;
		display: ${({ isPremium }) => (isPremium ? 'block' : 'none')};
		width: calc(100% + 8px);
		height: calc(100% + 8px);
		position: absolute;
		top: -4px;
		left: -4px;
		z-index: -1;
		${tw`bg-gradient-to-r from-blue-500 to-green-500`}
	}
`

export default PricingCard
