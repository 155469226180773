import React, { Fragment } from 'react'

import Seo from '../components/Seo'
import Button from '../components/Button'
import FeatureListItem from '../components/FeatureListItem'
import PricingCard from '../components/PricingCard'

import {
	GRUND_FEATURES,
	PREMIUM_FEATURES,
	FEATURE_LIST,
} from '../utils/featureData'

const Pricing = () => (
	<Fragment>
		<Seo
			title="Förenkla uppdraget som god man."
			description="Bokföring, dagbok & körjournal, årsräkning med mera. Allt samlat i en flexibel lösning för mobil och dator, skyddat med inloggning via BankID."
		/>
		{/* Hero Section */}
		<div className="container mx-auto pt-8 pb-2">
			<section className="flex flex-col items-center md:w-8/12 lg:w-6/12 px-4 pt-12 mb-12 lg:mb-24 text-center md:mx-auto">
				<h1 className="text-3xl mb-4 md:text-4xl lg:text-5xl font-futura font-semibold leading-tight">
					Abonnemang för alla uppdrag
				</h1>
				<p className="text-base md:text-lg text-gray-800">
					I våra appar administrerar du smidigt och säkert ditt
					uppdrag som god man eller förvaltare.
				</p>
			</section>
		</div>
		{/* Subscription Cards */}
		<section className="bg-gray-100 transform -skew-y-3 pt-12 mt-24 md:mt-48 mb-24">
			<div className="transform skew-y-3 -mt-24 md:-mt-48 pb-12 md:pb-24">
				<div className="container mx-auto flex justify-center px-4">
					<section className="flex flex-col md:flex-row items-center md:items-start space-y-6 md:space-y-0 md:space-x-4 lg:space-x-6 w-full md:w-10/12 lg:w-8/12">
						<PricingCard
							title="Grund"
							description="Grundläggande funktioner för dig som vill prova att använda Aider."
							buttonTitle="Kom igång nu"
							buttonHref="https://app.aider.nu/skapa-konto"
							buttonVariant="secondary"
							price={
								<span className="font-medium text-gray-600">
									<span className="text-2xl text-black">
										0kr{' '}
									</span>
									per år
								</span>
							}
							features={GRUND_FEATURES}
						/>
						<PricingCard
							isPremium
							title="Premium"
							description="En digital helhetstjänst för ditt uppdrag som god man."
							buttonTitle="Prova 30 dagar"
							buttonHref="https://app.aider.nu/skapa-konto"
							buttonVariant="primary"
							price={
								<span className="font-medium text-gray-600">
									från{' '}
									<span className="text-2xl text-black">
										190kr{' '}
									</span>
									per år och huvudman *
								</span>
							}
							priceNote="* Tillkommande huvudmän 190 kr per år, dock max 950 kr per år vid 5 huvudmän eller fler."
							features={PREMIUM_FEATURES}
							featureNote="Allt i Grund plus ..."
						/>
					</section>
				</div>
			</div>
		</section>
		{/* Subscription Cards */}
		<section className="mb-24">
			<div className="container mx-auto px-4">
				<div className="mx-auto w-full lg:w-10/12">
					<Fragment>
						<div className="hidden md:flex justify-end sticky top-0 bg-white py-6">
							<div className="w-1/3 text-center">
								<h4 className="text-lg font-semibold mb-2">
									Grund
								</h4>
								<Button
									title="Kom igång nu"
									variant="secondary"
									buttonHref="https://app.aider.nu/skapa-konto"
								/>
							</div>
							<div className="w-1/3 text-center">
								<h4 className="text-lg font-semibold mb-2">
									Premium
								</h4>

								<Button
									title="Prova 30 dagar"
									variant="primary"
									buttonHref="https://app.aider.nu/skapa-konto"
								/>
							</div>
						</div>
						{FEATURE_LIST.map(({ title, features }, index) => (
							<div key={title + index} className="mb-24">
								<h3 className="font-semibold text-lg mb-2">
									{title}
								</h3>
								<ul>
									{features.map(item => (
										<FeatureListItem
											key={item.title + index}
											{...item}
										/>
									))}
								</ul>
							</div>
						))}
					</Fragment>
				</div>
			</div>
		</section>
	</Fragment>
)

export default Pricing
