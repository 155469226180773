export const GRUND_FEATURES = [
	'Inloggning med BankID',
	'Digital dagbok',
	'Körjournal',
	'App för Android och iPhone',
	'Webbtjänst',
	'Meddelanden',
]

export const PREMIUM_FEATURES = [
	'Smidig bokföring',
	'Ekonomisk översikt',
	'Års- och sluträkningar',
	'Import av transaktioner',
]

export const FEATURE_LIST = [
	{
		title: 'Allmänt',
		features: [
			{
				title: 'Applikation för iPhone',
				free: true,
			},
			{
				title: 'Applikation för Android',
				free: true,
			},
			{
				title: 'Applikation för webb',
				free: true,
			},
			{
				title: 'Inloggning med BankID',
				free: true,
			},
			{
				title: 'Automatiska uppdateringar ',
				free: true,
			},
			{
				title: 'Tjänstens samtliga funktioner på svenska',
				free: true,
			},
		],
	},
	{
		title: 'Dagbok och körjournal',
		features: [
			{
				title: 'Strukturerad dagboksjournal',
				free: true,
			},
			{
				title: 'Körjournal',
				free: true,
			},
		],
	},
	{
		title: 'Meddelanden',
		features: [
			{
				title: 'Säker tvåvägs-kommunikation med Överförmyndaren',
				free: true,
			},
			{
				title: 'Anmäl intresse för nya uppdrag via Meddelanden',
				free: true,
			},
			{
				title: 'Bifoga dokument och underlag via Meddelanden',
				free: true,
			},
		],
	},
	{
		title: 'Support',
		features: [
			{
				title: 'Integrerat Hjälpcenter för utbildning, guider och instruktioner',
				free: true,
			},
			{
				title: 'Integrerad chatt-funktion för stöd och support',
				free: true,
			},
		],
	},
	{
		title: 'Säkerhet och data',
		features: [
			{
				title: 'Uppkoppling mot Folkbokföringen för verifiering av persondata',
				free: true,
			},
			{
				title: 'Säker lagring och backup av alla data ',
				free: true,
			},
			{
				title: 'Strukturerad export av all data om du vill avsluta ditt konto',
				free: true,
			},
			{
				title: 'Kryptering av data vid överföringar',
				free: true,
			},
		],
	},
	{
		title: 'Redovisning',
		features: [
			{
				title: 'Import av transaktionsdata via fil från Sveriges ledande banker',
				free: false,
			},
			{
				title: 'Import av transaktionsdata via BankID från utvalda svenska banker',
				free: false,
			},
			{
				title: 'Bokföring med smarta funktioner för inkomst- och utgiftskategorisering',
				free: false,
			},
			{
				title: 'Flervalsfunktioner för att dela upp transaktioner i flera kategorier',
				free: false,
			},
			{
				title: 'Funktion för hantering av brutto- och nettobelopp',
				free: false,
			},
			{
				title: 'Möjlighet att bifoga digitalt underlag till alla transaktioner',
				free: false,
			},
			{
				title: 'Digital lagring av alla transaktionsunderlag',
				free: false,
			},
			{
				title: 'Inskanning av transaktionsunderlag via mobilkamera',
				free: false,
			},
			{
				title: 'Anteckningsmöjlighet i fritext kopplat till alla transaktioner',
				free: false,
			},
			{
				title: 'Översiktlig sammanställning av bokföringen i siffror',
				free: false,
			},
			{
				title: 'Översiktlig sammanställning av bokföringen i grafisk form',
				free: false,
			},
			{
				title: 'Funktioner för filtrering och sök, baserat på tid, kategori m.m.',
				free: false,
			},
			{
				title: 'Verktyg för generering av lagstyrd årsräkning',
				free: false,
			},
			{
				title: 'Hjälpfunktioner för feleliminering i samband med rapportgenerering',
				free: false,
			},
			{
				title: 'Automatiskt genererad kassabok',
				free: false,
			},
			{
				title: 'Alla verifikat, noteringar och underlag sparas digitalt ',
				free: false,
			},
			{
				title: 'Automatisk funktion för krypterad sammanställning av rapporter, underlag och digitala verifikat',
				free: false,
			},
			{
				title: 'Automatisk sammanställning av strukturerade rapporter, underlag och verfikat',
				free: false,
			},
			{
				title: 'Funktion för utskrift av rapporter, underlag och verifikat',
				free: false,
			},
		],
	},
]
