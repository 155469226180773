import React from 'react'
import { Link } from 'gatsby'
import classNames from 'classnames'

const VARIANTS = {
	primary:
		'text-white bg-blue-500 hover:bg-blue-600 focus:bg-blue-600 focus:ring-2 ring-blue-400',
	secondary:
		'text-black border-gray-300 bg-white hover:bg-gray-200 focus:ring-2 ring-gray-400',
	tertiary: 'text-black bg-white-none hover:bg-gray-300 focus:bg-gray-300',
	destructive: 'text-white bg-red focus:ring-2 ring-red',
}

const Button = ({ to, href, className, variant, title, ...props }) => {
	const classes = classNames(
		'border border-transparent relative focus:outline-none rounded-lg disabled:bg-gray-400 disabled:text-gray-700 disabled:cursor-not-allowed outline-none px-5 py-2 font-medium transition duration-300',
		className,
		(variant && VARIANTS[variant]) || null,
	)

	// Decide if we should render a Link, anchor or button element
	// eslint-disable-next-line no-nested-ternary
	const Component = to ? Link : href ? 'a' : 'button'

	return (
		<Component to={to} href={href} className={classes} {...props}>
			{title}
		</Component>
	)
}

export default Button
